// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-electrical-testing-services-in-essex-js": () => import("/opt/build/repo/src/pages/electrical-testing-services-in-essex.js" /* webpackChunkName: "component---src-pages-electrical-testing-services-in-essex-js" */),
  "component---src-pages-electrical-testing-services-in-london-js": () => import("/opt/build/repo/src/pages/electrical-testing-services-in-london.js" /* webpackChunkName: "component---src-pages-electrical-testing-services-in-london-js" */),
  "component---src-pages-electrical-testing-services-in-norfolk-js": () => import("/opt/build/repo/src/pages/electrical-testing-services-in-norfolk.js" /* webpackChunkName: "component---src-pages-electrical-testing-services-in-norfolk-js" */),
  "component---src-pages-electrical-testing-services-in-suffolk-js": () => import("/opt/build/repo/src/pages/electrical-testing-services-in-suffolk.js" /* webpackChunkName: "component---src-pages-electrical-testing-services-in-suffolk-js" */),
  "component---src-pages-emergency-light-testing-js": () => import("/opt/build/repo/src/pages/emergency-light-testing.js" /* webpackChunkName: "component---src-pages-emergency-light-testing-js" */),
  "component---src-pages-fixed-wire-testing-js": () => import("/opt/build/repo/src/pages/fixed-wire-testing.js" /* webpackChunkName: "component---src-pages-fixed-wire-testing-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pat-testing-js": () => import("/opt/build/repo/src/pages/pat-testing.js" /* webpackChunkName: "component---src-pages-pat-testing-js" */),
  "component---src-pages-policies-js": () => import("/opt/build/repo/src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-remedial-repairs-js": () => import("/opt/build/repo/src/pages/remedial-repairs.js" /* webpackChunkName: "component---src-pages-remedial-repairs-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

